const convertedVapidKey = urlBase64ToUint8Array(
  'BKt_SoiVrkU9gwcwpuhWN4a2_00UT_U7XDVhyofMiwT3hPgclYNmxzL-xh8T3VgejY2HNIOg99E0-e9hx0l_vn4'
);

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// function sendSubscription(subscription) {
//   return fetch(`${process.env.REACT_APP_BASEURL}/pushNotifications/:id`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       subscription_json: JSON.stringify(subscription),
//     }),
//   });
// }
//conditional render
let clicked = true;

export function subscribeUser() {
  if (clicked) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          if (!registration.pushManager) {
            console.log('Push manager unavailable.');
            return;
          }

          registration.pushManager
            .getSubscription()
            .then(function (existedSubscription) {
              if (existedSubscription === null) {
                console.log('No subscription detected, make a request.');
                registration.pushManager
                  .subscribe({
                    applicationServerKey: convertedVapidKey,
                    userVisibleOnly: true,
                  })
                  .then(function (newSubscription) {
                    console.log('New subscription added.', newSubscription);
                    //sendSubscription(newSubscription);
                  })
                  .catch(function (e) {
                    if (Notification.permission !== 'granted') {
                      console.log('Permission was not granted.');
                    } else {
                      console.error(
                        'An error ocurred during the subscription process.',
                        e
                      );
                    }
                  });
              } else {
                console.log('Existed subscription detected.');
                //sendSubscription(existedSubscription);
              }
            });
        })
        .catch(function (e) {
          console.error(
            'An error ocurred during Service Worker registration.',
            e
          );
        });
    }
  } else {
    console.log('Can not reachable to the service worker');
  }
}
