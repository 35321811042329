import { atom } from 'recoil';

const uiAtom = atom({
  key: 'uiAtom',
  default: {
    loginModalOpen: false,
    developmentFinanceSidebarOpen: false,
  },
});

export default uiAtom;
