import React, { lazy, Suspense } from 'react';
import './styles/global.css';
import './styles/browserDefault.scss';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'react-toastify/dist/ReactToastify.css';

import useAuth from './hooks/useAuth';
// import PremiumRoute from './UI/PremiumRoute';
const Layout = lazy(() => import('./UI/Layout'));
// Pages
const Home = lazy(() => import('./pages/Home'));
const SubSectors = lazy(() => import('./Components/Sectors/SubSectors'));
const SearchResult = lazy(() => import('./Components/SearchResult'));
const Articles = lazy(() => import('./pages/Articles'));
const NairaValue = lazy(() => import('./pages/NairaValue'));
const Sectors = lazy(() => import('./pages/Sectors'));
const Infographic = lazy(() => import('./pages/Infographic'));
const Games = lazy(() => import('./pages/Games'));
const Datasets = lazy(() => import('./pages/Datasets'));
const Game = lazy(() => import('./pages/Game'));
const Blog = lazy(() => import('./pages/Blog'));
const Contact = lazy(() => import('./pages/Contact'));
const Payment = lazy(() => import('./pages/Payment'));
const About = lazy(() => import('./pages/About'));
const NotFound = lazy(() => import('./pages/NotFound'));
const History = lazy(() => import('./pages/History'));
const Leaderboard = lazy(() => import('./pages/Leaderboard'));
const Timeline = lazy(() => import('./pages/Timeline'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const PrivacyNotice = lazy(() => import('./pages/PrivacyNotice'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));

const DevelopmentFinance = lazy(() => import('./pages/DevelopmentFinance'));
// const ThankYou = lazy(() =>
//   import('./pages').then((module) => ({ default: module.ThankYou }))
// );
const FinanceJob = lazy(() => import('./Components/DevelopmentFinance/Job'));
const DevFinanceMetrics = lazy(
  () => import('./Components/DevelopmentFinance/Metrics')
);
const NewsHeadlines = lazy(() => import('./pages/NewsHeadlines'));
// const Subscription = lazy(() =>
//   import('./pages').then((module) => ({ default: module.Subscription }))
// );
// const MySubscriptions = lazy(() =>
//   import('./pages').then((module) => ({ default: module.MySubscriptions }))
// );
const Logout = lazy(() => import('./pages/Logout'));
const Dashboard = lazy(() => import('./Dashboard/App'));
const DashboardMetricsPage = lazy(
  () => import('./Dashboard/pages/ReferenceMetrics')
);
const DashboardFederalPage = lazy(() => import('./Dashboard/pages/Federal'));
const DashboardStatePage = lazy(() => import('./Dashboard/pages/StatePage'));

const App = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { toggleLoginPopup } = useAuth();

  useEffect(() => {
    AOS.init(); // initialize AOS
  }, []);
  // open
  useEffect(() => {
    if (state?.openLoginPopup) {
      toggleLoginPopup();
      navigate('', { state: { openLoginPopup: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.openLoginPopup]);

  return (
    <>
      {/* {!isDashboardRoute && <Navbar />} */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="games" element={<Games />} />
            <Route path="datasets" element={<Datasets />} />
            <Route path="games/:game" element={<Game />} />
            {/* <Route path="my-subscription" element={<MySubscriptions />} /> */}
            <Route path="leaderboard" element={<Leaderboard />} />
            <Route path="history" element={<History />} />
            <Route
              path="sectors"
              element={
                // <PremiumRoute>
                <Sectors />
                // </PremiumRoute>
              }
            />
            <Route path="blog/:slug" element={<Blog />} />
            <Route path="sectors/:name" element={<SubSectors />} />

            <Route path="search" element={<SearchResult />} />
            <Route path="articles" element={<Articles />} />
            <Route path="article/:slug" element={<Blog />} />
            <Route path="contact" element={<Contact />} />
            <Route path="payment" element={<Payment />} />
            <Route path="naira_value" element={<NairaValue />} />
            <Route path="logout" element={<Logout />} />
            <Route
              path="infographics"
              element={
                // <PremiumRoute>
                <Infographic />
                // </PremiumRoute>
              }
            />
            <Route path="about" element={<About />} />
            <Route
              path="development-finance"
              element={
                // <PremiumRoute>
                <DevelopmentFinance />
                // </PremiumRoute>
              }
            >
              <Route index element={<Navigate to="job" />} />
              <Route path="job" element={<FinanceJob />} />
              <Route path="metrics" element={<DevFinanceMetrics />} />
            </Route>
            {/* <Route
              path="subscription"
              element={
                <PremiumRoute>
                  <Subscription />
                </PremiumRoute>
              }
            /> */}
            <Route path="timeline" element={<Timeline />} />
            <Route path="news-headlines" element={<NewsHeadlines />} />
            <Route path="cookie_policy" element={<CookiePolicy />} />
            <Route path="privacy_notice" element={<PrivacyNotice />} />
            <Route path="terms_of_use" element={<TermsOfUse />} />
            {/* <Route path="thank-you" element={<ThankYou />} /> */}
            <Route path="*" element={<NotFound />} />
            {/* <Footer /> */}
            <Route path="dashboard/*" element={<Dashboard />}>
              <Route
                path="federal"
                index={true}
                element={<DashboardFederalPage />}
              />
              <Route path="state" element={<DashboardStatePage />} />
              <Route path="metrics" element={<DashboardMetricsPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
