import { toast } from 'react-toastify';

const handleAxiosError = (error) => {
  let errorMessage;

  if (error.response) {
    if (error?.response?.status >= 500) {
      errorMessage = 'Something went wrong!';
    } else {
      errorMessage = error?.response?.data?.message;
    }
  } else if (error.request) {
    errorMessage = 'No response received from server.';
  } else {
    errorMessage = error.message;
  }

  console.error(errorMessage);

  return errorMessage;
};
const catchAsync = (fn) => {
  return (...args) => {
    return fn(...args).catch((error) => {
      const errorMessage = handleAxiosError(error);
      toast(errorMessage, { type: 'error' });
      throw new Error(errorMessage);
    });
  };
};

export default catchAsync;
