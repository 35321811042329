import axios from '../axios';
import catchAsync from '../utils/catchAsync';

// login
export const getOrCreateUser = catchAsync(async (body) => {
  const { data } = await axios.post('/users', body);
  return data;
});

// logout
export const logout = catchAsync(async () => {
  await axios.get('/users/logout');
});
