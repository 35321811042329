import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom/client';
import 'aos/dist/aos.css';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { subscribeUser } from './subscription';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ReactQueryDevtools initialIsOpen={false} />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </BrowserRouter>
);

serviceWorker.register();

subscribeUser();
