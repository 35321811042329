import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import authAtom from '../atoms/authAtom';
import { getOrCreateUser, logout } from '../api/userApi';
import catchAsync from '../utils/catchAsync';
import uiAtom from '../atoms/uiAtoms';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();
  const [{ loginModalOpen }, setUi] = useRecoilState(uiAtom);
  const [user, setUser] = useRecoilState(authAtom);

  const getUserData = async (accessToken) => {
    const response = await axios.get(
      'https://www.googleapis.com/oauth2/v2/userinfo',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  };
  const createUser = catchAsync(async (body) => {
    const { data } = await getOrCreateUser(body);
    // store token to localstorage
    localStorage.setItem('token', body.token);
    setUser(data);
  });

  const loginHandlerGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      const accessToken = response.access_token;
      try {
        const userData = await getUserData(accessToken);
        const body = { ...userData, token: accessToken };
        /// store to db
        await createUser(body);
        const redirectLink =
          sessionStorage.getItem('redirect_route') || '/games';
        navigate(redirectLink);
      } catch (error) {
        const msg = error || 'Something went wrong!';
        alert(msg);
      }
    },

    onError: (err) => {
      console.log(err);
    },
  });
  const loginHandlerFacebook = async (response) => {
    // console.log("Get Profile Success!", response);
    const accessToken = localStorage.getItem('fb_access_token');
    localStorage.removeItem('fb_access_token');
    const body = {
      email: response.email,
      name: response.name,
      picture: response.picture.data.url,
      token: accessToken,
    };
    await createUser(body);
  };
  const logoutHandler = async () => {
    googleLogout();
    await logout();
    localStorage.removeItem('recoil-persist');
    localStorage.removeItem('token');
    window.location.assign('/');
  };
  const loginErrorHandler = () => {
    console.log('login fail');
  };
  const toggleLoginPopup = () => {
    setUi((prevState) => ({
      ...prevState,
      loginModalOpen: !prevState.loginModalOpen,
    }));
  };
  return {
    loginHandlerGoogle,
    loginHandlerFacebook,
    loginErrorHandler,
    logoutHandler,
    user,
    loginModalOpen,
    toggleLoginPopup,
  };
};

export default useAuth;
